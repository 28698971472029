import React, {Component} from 'react';
import axios from 'axios';
import Project from "./project.component";

export default class ProjectsList extends Component {

    constructor(props) {
        super(props);

        this.state = {projects: []};
    }

    componentDidMount() {
        axios.get('https://egmond-dev-api.egmd.dev/projects')
            .then(res => {
                this.setState({projects: res.data});
            })
            .catch((error) => {
                console.log(error);
            })
    }

    projectsList() {
        return this.state.projects.map(currentProject => {
            return <Project project={currentProject} key={currentProject._id}/>
        });
    }

    render() {
        return (
            <div id="projecten" className="container mx-auto px-8">

                <h2 className="purple-label purple-gradient">
                    Projecten
                </h2>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pt-6">
                    {this.projectsList()}
                </div>

            </div>
        )
    }
}
