import React, {Component} from 'react';
import axios from "axios";
import Project from "./partials/project.component";

export default class ProjectDetail extends Component {

    constructor(props) {
        super(props);

        this.state = {
            project: {
                _id: "",
                title: "",
                slug: "",
                description: "",
                body: "",
                tags: [],
                images: [],
                url: "",
                year: ""
            },
            randomProjects: [],
            loading: true,
        };
    }

    componentDidMount() {
        this.getProjectBySlug();
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.slug !== prevProps.match.params.slug) {
            this.getProjectBySlug();
        }
    }

    getProjectBySlug() {
        axios.get('https://egmond-dev-api.egmd.dev/projects/' + this.props.match.params.slug)
            .then(response => {
                if (response.data) {
                    this.setState({
                        project: response.data,
                    });
                    document.title = 'Mike van Egmond - ' + this.state.project.title;
                    this.getRandomProjects();
                } else {
                    document.title = 'Mike van Egmond - Project niet gevonden';
                }

                this.setState({loading: false});
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    getRandomProjects() {
        axios.get('https://egmond-dev-api.egmd.dev/projects/random/' + this.state.project._id)
            .then(response => {
                this.setState({
                    randomProjects: response.data[0],
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    listRandomProjects() {
        return this.state.randomProjects.map(currentProject => {
            return <div key={currentProject._id} className="my-4"><Project project={currentProject}/></div>
        });
    }

    render() {
        let content = <div></div>;

        if (!this.state.loading && this.state.project._id) {
            content = (
                <div className="pt-16" key={this.props.match.params._id}>
                    <div className="container max-w-2xl mx-auto px-8">
                        <h1 className="text-4xl font-bold py-3">{this.state.project.title}</h1>

                        <img src={this.state.project.images[0]} alt="Project"/>

                        <p>{this.state.project.description}</p>
                    </div>

                    <div className="bg-gray-200 pt-2 pb-5 mt-5">
                        <div className="container max-w-2xl mx-auto px-8 mt-10">
                            <h2 className="text-3xl font-bold pb-3">Andere projecten.</h2>
                            {this.listRandomProjects()}
                        </div>
                    </div>
                </div>
            )
        } else if (!this.state.loading) {
            content = (
                <div className="pt-16 h-screen flex items-center justify-center">
                    <h1 className="text-4xl font-bold py-3">Geen project gevonden met deze naam.</h1>
                </div>
            )
        }

        return content;
    }
}
