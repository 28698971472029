import React, {Component} from 'react';
import {Link} from "react-router-dom";

export default class Nav extends Component {

    componentDidMount() {
    }

    render() {
        return (
            <nav className="fixed z-50 w-full h-16 shadow-lg" style={{backdropFilter: "blur(30px)"}}>

                <div className="h-full container mx-auto flex items-center">
                    <Link className="flex items-center" to={"/"}>
                        <img src="/images/logo/logo.svg" className="h-10 ml-3" alt=""/>
                        <h3 className="text-xl font-extrabold tracking-tight ml-2">Home</h3>
                    </Link>
                </div>


            </nav>
        );
    }
}
