import React, {Component} from 'react';

export default class Hero extends Component {

    componentDidMount() {
        let last_known_scroll_position = 0;
        let ticking = false;
        let hidden = false;
        let downCircle = document.getElementsByClassName('down-circle')[0];

        function hideDownCircle(scroll_pos) {
            if (scroll_pos > 100) {
                if (!hidden) {
                    downCircle.classList.remove('opacity-100');
                    downCircle.classList.add('opacity-0');
                }
                hidden = true;
            } else {
                if (hidden) {
                    downCircle.classList.remove('opacity-0');
                    downCircle.classList.add('opacity-100');
                }
                hidden = false;
            }
        }

        function scrollDown() {
            window.scrollTo({top: window.innerHeight - 20, behavior: 'smooth'});
        }

        window.addEventListener('scroll', function (e) {
            last_known_scroll_position = window.scrollY;

            if (!ticking) {
                window.requestAnimationFrame(function () {
                    hideDownCircle(last_known_scroll_position);
                    ticking = false;
                });

                ticking = true;
            }
        });

        downCircle.addEventListener("click", scrollDown);
    }

    render() {
        return (
            <header className="header-container bg-repeating">
                <div className="m-4 max-w-2xl">
                    <h1 className="title">
                        Mike van Egmond
                    </h1>
                    <h2 className="purple-label purple-gradient">
                        Developer
                    </h2>
                </div>
                <div className="m-4 max-w-xl">
                    <img src="./images/undraw_progressive_app.svg" alt="Progressive app, Undraw"/>
                </div>

                <div className="down-circle-container">

                    <div className="down-circle purple-gradient animate-bounce">
                        <div className="opacity-100 text-white text-lg">
                            <i className="fas fa-chevron-down"></i>
                        </div>
                    </div>

                </div>

            </header>
        );
    }
}
