import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import axios from "axios";

export default class Project extends Component {

    constructor(props) {
        super(props);

        this.state = {
            project: {
                _id: "",
                title: "",
                slug: "",
                description: "",
                body: "",
                tags: [],
                images: [],
                url: "",
                year: ""
            },
        };
    }

    componentDidMount() {

        axios.get('https://egmond-dev-api.egmd.dev/projects/' + this.props.project.slug)
            .then(response => {

                this.setState({
                    project: response.data,
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        return (
            <Link to={"/project/" + this.state.project.slug}>

                <div className="rounded-xl bg-white shadow-xl hover-effect cursor-pointer">

                    <img src={this.state.project.images[0]} alt="Project" className="rounded-t-xl"/>

                    <div className="p-5">

                        <h3 className="text-md tracking-tight font-bold text-gray-800 sm:text-lg md:text-xl mb-3">{this.state.project.title}</h3>
                        <p>{this.state.project.description}</p>
                        <div className="mt-4">
                            {
                                this.state.project.tags.map((tag, index) => {
                                    return <span
                                        className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 m-1"
                                        key={index}>{tag}</span>
                                })
                            }
                        </div>

                    </div>

                </div>

            </Link>


        )
    }
}
