import React, {Component} from 'react';

export default class VueComponentToNpmPackage extends Component {

    componentDidMount() {
        window.location.href = 'https://blog.egmond.dev/vue-component-to-npm-package';
    }

    render() {
        return (
          <div>Redirecting...</div>
        )
    }
}
