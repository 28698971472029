import React, {Component} from 'react';

export default class NotFound extends Component {

    componentDidMount() {
        document.title = 'Mike van Egmond - 404';
    }

    render() {
        return (
            <>
                <div className="pt-16 h-screen flex items-center justify-center">
                    <h1 className="text-4xl font-bold py-3">Pagina niet gevonden.</h1>
                </div>
            </>
        )
    }
}
