import React, {Component} from 'react';
import "../../css/Timeline.css";

export default class Timeline extends Component {

    render() {
        return (
            <div className="container mx-auto p-4 pt-10">

                <h2 id="tijdlijn" className="purple-label purple-gradient">
                    Tijdlijn
                </h2>

                <ul className="timeline timeline-centered">

                    <li className="timeline-item">
                        <div className="timeline-info">
                            <span>2020</span>
                        </div>
                        <div className="timeline-marker"></div>
                        <div className="timeline-content">
                            <h3 className="timeline-title">Start opleiding HBO-ICT</h3>
                            <p>Begonnen met de opleiding HBO-ICT aan de Hogeschool van Arnhem en Nijmegen in Arnhem.</p>
                        </div>
                    </li>

                    <li className="timeline-item">
                        <div className="timeline-info">
                            <span>2020</span>
                        </div>
                        <div className="timeline-marker"></div>
                        <div className="timeline-content">
                            <h3 className="timeline-title">Opleiding applicatie ontwikkelaar afgerond</h3>
                            <p>De opleiding applicatie ontwikkelaar afgerond aan het Graafschap College.</p>
                        </div>
                    </li>

                    <li className="timeline-item">
                        <div className="timeline-info">
                            <span>feb 2020 - jun 2020</span>
                        </div>
                        <div className="timeline-marker"></div>
                        <div className="timeline-content">
                            <h3 className="timeline-title">Stage webdevelopment</h3>
                            <p>Stage gelopen bij Pinkcube, stage succesvol afgerond met een 9.</p>
                        </div>
                    </li>

                    <li className="timeline-item">
                        <div className="timeline-info">
                            <span>Sep 2019 - Feb 2020</span>
                        </div>
                        <div className="timeline-marker"></div>
                        <div className="timeline-content">
                            <h3 className="timeline-title">Junior front-end developer</h3>
                            <p>Werken als junior front-end developer bij Freave.</p>
                        </div>
                    </li>

                    <li className="timeline-item">
                        <div className="timeline-info">
                            <span>Aug 2018 - Feb 2019</span>
                        </div>
                        <div className="timeline-marker"></div>
                        <div className="timeline-content">
                            <h3 className="timeline-title">Stage applicatie ontwikkelaar</h3>
                            <p>Stage gelopen bij Freave in Doetinchem. Stage afgerond met een 9.</p>
                        </div>
                    </li>

                    <li className="timeline-item">
                        <div className="timeline-info">
                            <span>2017</span>
                        </div>
                        <div className="timeline-marker"></div>
                        <div className="timeline-content">
                            <h3 className="timeline-title">Start opleiding applicatie ontwikkelaar</h3>
                            <p>Begonnen met de opleiding applicatie ontwikkelaar aan het Graafschap College in
                                Doetinchem.</p>
                        </div>
                    </li>

                </ul>

            </div>
        );
    }
}
