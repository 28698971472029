import React, {Component} from 'react';

export default class Footer extends Component {

    componentDidMount() {
        let user = 'mikevanegmond1998',
            domain = 'gmail.com',
            element = document.getElementById('email');

        element.href = 'mailto:' + user + '@' + domain;
    }

    render() {
        return (
            <>
                <footer>

                    <div className="wave">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                            <path fill="#fff" fillOpacity="1"
                                  d="M0,256L40,250.7C80,245,160,235,240,218.7C320,203,400,181,480,154.7C560,128,640,96,720,106.7C800,117,880,171,960,176C1040,181,1120,139,1200,138.7C1280,139,1360,181,1400,202.7L1440,224L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
                        </svg>
                    </div>

                    <div className="container mx-auto flex justify-around items-start">

                        <a target="_blank" rel="noreferrer" href="https://github.com/mve"
                           className="link-card">
                            <div className="link-card-icon">
                                <i className="fab fa-github"></i>
                            </div>
                            <div className="link-card-text">
                                GitHub
                            </div>
                        </a>

                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/mike-van-egmond/"
                           className="link-card">
                            <div className="link-card-icon">
                                <i className="fab fa-linkedin-in"></i>
                            </div>
                            <div className="link-card-text">
                                LinkedIn
                            </div>
                        </a>

                        <a target="_blank" rel="noreferrer" href="/" id="email" className="link-card">
                            <div className="link-card-icon">
                                <i className="fas fa-at"></i>
                            </div>
                            <div className="link-card-text">
                                Email
                            </div>
                        </a>


                    </div>

                </footer>

            </>

        );
    }
}
