import React, {Component} from 'react';
import Hero from "./partials/hero.component";
import ProjectsList from "./partials/projects-list.component";
import Footer from "./partials/footer.component";
import Timeline from "./partials/timeline.component";

export default class Home extends Component {

    componentDidMount() {
        document.title = 'Mike van Egmond - Web developer uit Doetinchem';
    }

    render() {
        return (
            <>
                <Hero/>
                <main className="bg-white">
                    <Timeline/>
                    <ProjectsList/>
                </main>
                <Footer/>
            </>
        )
    }
}
