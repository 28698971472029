import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./components/home.component";
import ProjectDetail from "./components/project-detail.component";
import ScrollToTop from "./components/partials/scrollToTop.component";
import Nav from "./components/partials/nav.component";
import VueComponentToNpmPackage from "./components/blog/vue-component-to-npm-package.component";
import NotFound from "./components/not-found.component";

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Nav/>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route path="/project/:slug" exact component={ProjectDetail}/>
                <Route path="/blog/vue-component-to-npm-package" exact component={VueComponentToNpmPackage}/>
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
}

export default App;
